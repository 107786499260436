import {PropsWithChildren} from 'react';
import * as inputStyles from './input.module.css';
import * as styles from './dateSelect.module.css';

const DateSelectWrapper = ({
    children,
}: PropsWithChildren) => (
    <span className={inputStyles.root}>
        <span className={inputStyles.inputWrapper}>
            <span className={styles.titleWrapper}>
                <span className={styles.title}>
                    Start date
                </span>
                <span className={styles.title}>
                    End date
                </span>
            </span>
            {children}
        </span>
    </span>
);

export default DateSelectWrapper;
