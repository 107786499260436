import {Link, navigate, PageProps} from 'gatsby';
import {
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react';
import {
    useQuery,
    useMutation,
    useLazyQuery,
    useApolloClient,
} from '@apollo/client';
import {
    useForm,
    FieldValues,
    UseFormReset,
    Controller,
    useWatch,
} from 'react-hook-form';
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import clsx from 'clsx';
import {
    Tabs,
    Button,
    Card,
    Cards,
    Checkbox,
    CopyTextIcon,
    Form,
    Input,
    InputPrefix,
    InputReadOnly,
    Textarea,
    Tooltip,
    SelectBox,
    Spacer,
} from '../../../../components/core';
import {Layout, StatusBar} from '../../../../components/layout';
import {AddIcon, DeleteIcon, NavArrowLeftIcon} from '../../../../components/core/icons';
import LoadingIndicator from '../../../../components/core/loading/loadingIndicator';
import WarningPopup from '../../../../components/core/warningPopup/warningPopup';
import DateSelectWrapper from '../../../../components/core/form/input/dateSelect';
import {
    GetVideoMutationsDocument,
    AddVideoMutationDocument,
    GetMutationDocument,
    EditVideoMutationDocument,
    DeleteVideoMutationDocument,
    GetMutationQuery,
    EditVideoMutationMutation,
    VideoVersion,
    VideoUploadVersion,
    CheckYoutubeVideoIdExistsDocument,
    LanguageOrderBy,
    OrderWay,
    GetChangelogDocument,
} from '../../../../graphql-types';
import pickDirtyFields from '../../../../helpers/form/pickDirtyFields';
import isDirtyForm from '../../../../helpers/form/isDirtyForm';
import validateYoutubeVideoId from '../../../../helpers/validators/youtubeVideoId';
import toastify, {ToastLocationState} from '../../../../helpers/toast/toastify';
import {getVideoTabsData} from '../../../../configs/pages/videos/tabs';
import HistoryModal from '../../../../modals/videos/historyModal';
import '../../../../components/core/form/input/dateSelect.css';
import {VideoModalVariants} from '../../../../configs/pages/videos/modals';
import * as layoutStyles from '../../../../components/layout/layout.module.css';
import * as styles from './mutations.module.css';
import * as videoStyles from './common.module.css';
import TagsInput from '../../../../components/core/form/input/tagsInput';
import validateYoutubeVideoTags from '../../../../helpers/validators/youtubeVideoTags';
import {GLOBAL_LANGUAGE_ID} from '../../../../configs/vars';
import {getVideoURL} from '../../../../configs/pages/videos/videoUrl';

interface LocationState {
    activeMutationId: string,
}

type VideoMutationsEditPageTypeProps = {video: string};
type VideoMutationsEditPageType = PageProps<null, null, LocationState & ToastLocationState> & VideoMutationsEditPageTypeProps;

interface IResetMutationData<T extends Record<string, any>> {
    data: T,
    reset: UseFormReset<FieldValues>,
    dataName: keyof T,
}

async function resetMutationData<T extends Record<string, any>>({
    data,
    dataName,
    reset,
}: IResetMutationData<T>) {
    if (!data) {
        return;
    }

    reset({
        title: data[dataName]?.title,
        name: data[dataName]?.name,
        languageId: data[dataName]?.language?.id ?? null,
        captionFile: data[dataName]?.captionFile,
        audioFile: data[dataName]?.audioFile,
        startDate: data[dataName]?.startDate ? moment.utc(data[dataName]?.startDate).format() : null,
        endDate: data[dataName]?.endDate ? moment.utc(data[dataName]?.endDate).format() : null,
        createdAt: new Date(data[dataName]?.createdAt).toLocaleDateString('en-GB'),
        description: data[dataName]?.description,
        note: data[dataName]?.note,
        upload: {
            notifySubscribers: false,
            uploadPolicyId: data[dataName]?.upload.uploadPolicy?.id ?? null,
            privacy: data[dataName]?.upload.privacy ?? null,
            videoId: data[dataName]?.upload.videoId,
            uploadVersion: data[dataName]?.upload.uploadVersion,
            categoryId: data[dataName]?.upload.category?.id ?? null,
            customThumbnail: data[dataName]?.upload?.customThumbnail,
            filename: data[dataName]?.upload.filename,
            customId: data[dataName]?.upload.customId,
            keywords: data[dataName]?.upload?.keywords,
        },
    });
}

const VideoMutationsEditPage = ({
    video: videoId,
    location,
    params,
}: VideoMutationsEditPageType) => {
    const {loading, data: videoMutations, refetch} = useQuery(
        GetVideoMutationsDocument,
        {
            variables: {videoId, order: {by: LanguageOrderBy.ShortName, way: OrderWay.Asc}},
        },
    );
    const apolloClient = useApolloClient();
    const [
        getMutation, {
            data: mutationData,
            loading: mutationLoading,
        },
    ] = useLazyQuery(GetMutationDocument, {initialFetchPolicy: 'network-only'});
    const [addVideoMutation] = useMutation(AddVideoMutationDocument);
    const [editMutation, {loading: isSendingEdit}] = useMutation(EditVideoMutationDocument);
    const [deleteMutation, {loading: isSendingDelete}] = useMutation(DeleteVideoMutationDocument);
    const [activeMutationId, setActiveMutationId] = useState<string | undefined>();
    const [activeUploadId, setActiveUploadId] = useState<string | undefined>();
    const [focusedDate, setFocusedDate] = useState<FocusedInputShape | null>(null);
    const videoTitle = useMemo(() => (videoMutations ? videoMutations.video?.mcbt : videoId), [videoId, videoMutations]);
    const videoTabsData = useMemo(() => getVideoTabsData(videoMutations?.video?.version, true), [videoMutations]);
    const [openedModal, setOpenedModal] = useState<string | boolean>(false);
    const [playlists, setPlaylists] = useState<Array<{id: string; name: string}>>([]);
    const [isInLanguageScopePlaylists, setIsInLanguageScopePlaylists] = useState<boolean>(false);

    const formMethods = useForm<FieldValues>({
        mode: 'all',
        defaultValues: {},
    });
    const {
        control,
        reset,
        handleSubmit,
        setValue,
        formState: {
            dirtyFields,
            isValid,
        },
    } = formMethods;
    const [
        startDate,
        endDate,
        videoIdToCopy,
        uploadVersion,
    ] = useWatch({
        control,
        name: [
            'startDate',
            'endDate',
            'upload.videoId',
            'upload.uploadVersion',
        ],
    });
    const [discardWarningDisplayed, setDiscardWarningDisplayed] = useState(false);
    const [onDiscardWarningConfirm, setOnDiscardWarningConfirm] = useState<() => () => void>(() => () => {});
    const [deleteWarningDisplayed, setDeleteWarningDisplayed] = useState(false);
    const [onDeleteWarningConfirm, setOnDeleteWarningConfirm] = useState<() => () => void>(() => () => {});
    const isDirty = isDirtyForm(dirtyFields);
    const isVO = useMemo(
        () => videoMutations?.video?.version === VideoVersion.VoiceOver,
        [videoMutations?.video?.version],
    );
    const pageTitle = `Video - ${isVO ? 'local versions' : 'translations'} | ${videoTitle}`;
    const hasEmptyMutation = useMemo(
        () => videoMutations?.video?.mutations.some(mutation => mutation.name === ''),
        [videoMutations],
    );
    const activeMutationName = useMemo(
        () => videoMutations?.video?.mutations.find(mutation => mutation.id === activeMutationId)?.name,
        [activeMutationId, videoMutations?.video?.mutations],
    );
    const checkMutationNameExists = useCallback((name: string) => {
        if (name === activeMutationName) {
            return false;
        }

        const usedMutationNames = videoMutations?.video?.mutations.map(mutation => mutation.name);

        return usedMutationNames?.includes(name);
    }, [activeMutationName, videoMutations?.video?.mutations]);
    const usedLanguages = useMemo(() => {
        if (!videoMutations?.video?.mutations) {
            return [];
        }

        return videoMutations?.video?.mutations.map(mutation => mutation?.language?.id);
    }, [videoMutations?.video?.mutations]);
    const languagesLeft = useMemo(() => {
        if (!videoMutations?.languages) {
            return [];
        }

        if (isVO) {
            return videoMutations?.languages;
        }

        return videoMutations?.languages.filter(language => !usedLanguages.includes(language?.id));
    }, [
        isVO,
        usedLanguages,
        videoMutations?.languages,
    ]);
    const usableLanguages = useMemo(() => {
        if (!videoMutations?.languages) {
            return [];
        }

        if (isVO) {
            return videoMutations?.languages;
        }

        return videoMutations?.languages.filter(language => {
            if (language?.id === mutationData?.mutation?.language?.id) {
                return true;
            }

            return !usedLanguages.includes(language?.id);
        });
    }, [
        isVO,
        mutationData?.mutation?.language?.id,
        usedLanguages,
        videoMutations?.languages,
    ]);
    const hasValidChanges = useMemo(() => isValid && isDirty, [isDirty, isValid]);
    const canAddNewMutation = useMemo(() => {
        let message = '';

        if (usableLanguages.length === 0 || languagesLeft.length === 0) {
            message = 'You can\'t add new mutation as there is no unique language left';
        }

        if (hasEmptyMutation) {
            message = `You can't add more than one empty ${isVO ? 'version' : 'translation'}`;
        }

        return ({
            state: message !== '',
            message,
        });
    }, [
        hasEmptyMutation,
        isVO,
        languagesLeft.length,
        usableLanguages.length,
    ]);
    const videoURLBase = useMemo(() => getVideoURL('', uploadVersion), [uploadVersion]);

    const handleAddMutation = useCallback(() => {
        const addMutation = () => {
            addVideoMutation({
                variables: {
                    addMutationId: videoId,
                },
                onCompleted(data) {
                    setDiscardWarningDisplayed(false);
                    toastify({
                        type: 'success',
                        text: `New ${isVO ? 'version' : 'translation'} added`,
                    });
                    refetch().then(() => {
                        setActiveMutationId(data.addMutation.id);
                    });
                },
                refetchQueries: [
                    {
                        query: GetChangelogDocument,
                        variables: {
                            filter: {
                                videoIds: [videoId],
                            },
                        },
                    },
                ],
                onQueryUpdated(observableQuery) {
                    return observableQuery.refetch();
                },
            });
        };

        if (hasValidChanges) {
            setOnDiscardWarningConfirm(() => () => {
                addMutation();
            });
            setDiscardWarningDisplayed(true);

            return;
        }

        addMutation();
    }, [
        addVideoMutation,
        hasValidChanges,
        isVO,
        refetch,
        videoId,
    ]);

    const handleDeleteMutation = useCallback(() => {
        if (!activeMutationId) {
            return;
        }

        setOnDeleteWarningConfirm(() => () => {
            deleteMutation({
                variables: {
                    deleteMutationId: activeMutationId,
                },
                onCompleted() {
                    refetch();
                    setActiveMutationId(videoMutations?.video?.mutations[0].id);
                    setDeleteWarningDisplayed(false);
                    window.scrollTo(0, 0);
                    toastify({
                        type: 'success',
                        text: 'Item deleted',
                    });
                },
                onError(err) {
                    toastify({
                        type: 'error',
                        text: `There was an error on item delete: ${err.message}`,
                    });
                },
                onQueryUpdated(observableQuery) {
                    return observableQuery.refetch();
                },
            });
        });

        setDeleteWarningDisplayed(true);
    }, [
        activeMutationId,
        deleteMutation,
        refetch,
        videoMutations?.video?.mutations,
    ]);

    const onSubmit = (formData: FieldValues) => {
        if (!activeMutationId) {
            return;
        }

        const newData = pickDirtyFields(formData, dirtyFields);

        editMutation({
            variables: {
                editMutationId: activeMutationId,
                input: newData,
            },
            onCompleted(data) {
                if (!data) {
                    toastify({
                        type: 'error',
                        text: 'There was an error on item update',
                    });

                    return;
                }

                if (data.editMutation.upload?.playlists) {
                    setIsInLanguageScopePlaylists(false);
                    setPlaylists(data.editMutation.upload.playlists.filter(playlist => {
                        if (playlist.language.id === GLOBAL_LANGUAGE_ID) {
                            return true;
                        }

                        if (data.editMutation?.language?.id === playlist.language.id) {
                            setIsInLanguageScopePlaylists(true);
                        }

                        return false;
                    }));
                }

                resetMutationData<EditVideoMutationMutation>({
                    reset,
                    data,
                    dataName: 'editMutation',
                });
                toastify({
                    type: 'success',
                    text: 'Item updated',
                });
            },
            onError(err) {
                toastify({
                    type: 'error',
                    text: `There was an error on item update: ${err.message}`,
                });
            },
            refetchQueries: [
                {
                    query: GetChangelogDocument,
                    variables: {
                        filter: {
                            mutationIds: [mutationData?.mutation?.id],
                        },
                    },
                }, {
                    query: GetChangelogDocument,
                    variables: {
                        filter: {
                            videoIds: [videoId],
                        },
                    },
                },
            ],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    const fetchMutationData = async (mutationId: string) => {
        const {data} = await getMutation({variables: {mutationId}});

        if (!data || !data.mutation) {
            return;
        }

        if (data.mutation.upload?.videoId) {
            setActiveUploadId(data.mutation.upload?.videoId);
        }

        if (data.mutation.upload?.playlists) {
            setIsInLanguageScopePlaylists(false);
            setPlaylists(data.mutation.upload.playlists.filter(playlist => {
                if (playlist.language.id === GLOBAL_LANGUAGE_ID) {
                    return true;
                }

                if (data.mutation?.language?.id === playlist.language.id) {
                    setIsInLanguageScopePlaylists(true);
                }

                return false;
            }));
        }

        resetMutationData<GetMutationQuery>({
            reset,
            data,
            dataName: 'mutation',
        });
    };

    const handleMutationListClick = async (mutationId: string) => {
        const runActions = () => {
            setActiveMutationId(mutationId);
            fetchMutationData(mutationId);
        };

        if (hasValidChanges) {
            setOnDiscardWarningConfirm(() => () => {
                runActions();
                setDiscardWarningDisplayed(false);
            });

            setDiscardWarningDisplayed(true);

            return;
        }

        runActions();
    };

    const interceptLinks = {
        status: !!(hasValidChanges),
        action: () => {
            setDiscardWarningDisplayed(true);
        },
        setOnConfirm: setOnDiscardWarningConfirm,
    };

    useEffect(() => {
        if (
            !videoMutations
            || videoMutations.video?.mutations.length === 0
            || ((videoMutations.video?.mutations || []).length > 1 && activeMutationId)
        ) {
            return;
        }

        const defaultMutationId = location.state?.activeMutationId || videoMutations.video?.mutations[0].id;

        if (!defaultMutationId) {
            return;
        }

        setActiveMutationId(defaultMutationId);
    }, [
        activeMutationId,
        location.state?.activeMutationId,
        videoMutations,
    ]);

    useEffect(() => {
        if (!activeMutationId) {
            return;
        }

        fetchMutationData(activeMutationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeMutationId]);

    useEffect(() => {
        if (!location?.state?.toast) {
            return;
        }

        toastify(location.state.toast);
        window.history.replaceState(null, '');
    }, [location?.state?.toast]);

    return (
        <>
            <Layout
                title={pageTitle}
                scope="videos.write"
                interceptLinks={interceptLinks}
            >
                <div className={layoutStyles.pageHeader}>
                    <StatusBar
                        buttons={(
                            <>
                                <Button
                                    icon={NavArrowLeftIcon}
                                    text="Back to videos"
                                    onClick={() => {
                                        const to = '../../';

                                        if (hasValidChanges) {
                                            setOnDiscardWarningConfirm(() => () => {
                                                navigate(to);
                                            });
                                            setDiscardWarningDisplayed(true);

                                            return;
                                        }

                                        navigate(to);
                                    }}
                                    variant="outline"
                                />
                                <Button
                                    text="Save changes"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={!hasValidChanges || isSendingEdit === true}
                                    loading={isSendingEdit === true}
                                />
                            </>
                        )}
                        title={(
                            <>
                                <span>Video /&nbsp;</span>
                                <strong>{videoMutations ? videoMutations.video?.mcbt : videoTitle}</strong>
                            </>
                        )}
                    />
                </div>
                <div>
                    <Tabs
                        data={videoTabsData}
                        interceptLinks={interceptLinks}
                    />
                </div>
                {loading && (
                    <LoadingIndicator/>
                )}
                {!loading && videoMutations && (
                    <div className={layoutStyles.pageContent}>
                        <div className={styles.mutationCardsWrapper}>
                            <div className={styles.mutationCards}>
                                <Cards>
                                    {videoMutations.video?.mutations.map(mutation => (
                                        <Card
                                            key={mutation.id}
                                            title={mutation.name === '' ? '<empty>' : mutation.name}
                                            hideArrow={true}
                                            hasWarning={mutation.name === ''}
                                            isActive={activeMutationId === mutation.id}
                                            onClick={() => handleMutationListClick(mutation.id)}
                                        />
                                    ))}
                                </Cards>
                            </div>
                            <div className={styles.mutationCardsButton}>
                                <Tooltip
                                    disabled={canAddNewMutation.state !== true}
                                    text={canAddNewMutation.message}
                                >
                                    <Button
                                        text={`Add new ${isVO ? 'version' : 'translation'}`}
                                        onClick={handleAddMutation}
                                        icon={AddIcon}
                                        disabled={canAddNewMutation.state}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        {mutationLoading && (
                            <LoadingIndicator/>
                        )}
                        {!mutationLoading && activeMutationId && (
                            <>
                                <div className={layoutStyles.pageSection}>
                                    <Spacer height="1.5rem"/>
                                    <h2>{isVO ? 'Local version' : 'Translation'} info</h2>
                                    <Form methods={formMethods}>
                                        <Form.Row>
                                            <Form.Column>
                                                <Input
                                                    title="Title"
                                                    name="title"
                                                    control={control}
                                                    rules={{
                                                        maxLength: {
                                                            value: 100,
                                                            message: 'Title is too long (max 100 characters)',
                                                        },
                                                        pattern: {
                                                            value: /^[^<>]*$/,
                                                            message: 'You can\'t use characters "<" or ">" in a video title',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <Input
                                                    title={`${isVO ? 'Local version' : 'Translation'} name`}
                                                    name="name"
                                                    control={control}
                                                    rules={{
                                                        maxLength: {
                                                            value: 255,
                                                            message: 'It can\'t be longer then 255 characters',
                                                        },
                                                        validate: value => {
                                                            if (value !== '' && checkMutationNameExists(value)) {
                                                                return 'This name is already used';
                                                            }

                                                            return true;
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <SelectBox
                                                    title="Language"
                                                    placeholder="Choose the language"
                                                    control={control}
                                                    controllerName="languageId"
                                                    optionsValues={
                                                        usableLanguages.map(language => ({
                                                            id: language?.id,
                                                            value: language?.id,
                                                            label: language?.shortName,
                                                        }))
                                                    }
                                                    isDisabled={isInLanguageScopePlaylists}
                                                    caption={isInLanguageScopePlaylists ? 'Cannot edit language if mutation is in a language specific playlist' : undefined}
                                                    canType={true}
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <InputReadOnly
                                                    title="Created date"
                                                    name="createdAt"
                                                    control={control}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <Input
                                                    title="Caption file name"
                                                    name="captionFile"
                                                    control={control}
                                                    rules={{
                                                        pattern: {
                                                            value: /^.*\.(srt)$/,
                                                            message: 'Not valid subtitles file format (.srt only)',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                            <Form.Column>
                                                <DateSelectWrapper>
                                                    <DateRangePicker
                                                        startDate={startDate ? moment(startDate) : null}
                                                        startDateId="mutationStartDate"
                                                        endDate={endDate ? moment(endDate) : null}
                                                        endDateId="mutationEndDate"
                                                        onDatesChange={({
                                                            startDate: newStartDate,
                                                            endDate: newEndDate,
                                                        }) => {
                                                            setValue(
                                                                'startDate',
                                                                newStartDate ? moment.utc(newStartDate).format() : null,
                                                                {shouldDirty: true},
                                                            );
                                                            setValue(
                                                                'endDate',
                                                                newEndDate ? moment.utc(newEndDate).format() : null,
                                                                {shouldDirty: true},
                                                            );
                                                        }}
                                                        focusedInput={focusedDate}
                                                        onFocusChange={focusedInput => {
                                                            if (!focusedInput) {
                                                                setFocusedDate(null);

                                                                return;
                                                            }

                                                            setFocusedDate(focusedInput);
                                                        }}
                                                        noBorder
                                                        firstDayOfWeek={1}
                                                        hideKeyboardShortcutsPanel
                                                        numberOfMonths={2}
                                                        isOutsideRange={() => false}
                                                        displayFormat="DD/MM/YYYY"
                                                        keepOpenOnDateSelect
                                                    />
                                                </DateSelectWrapper>
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column hasHalfWidth={true}>
                                                <Input
                                                    title="Audio Track File Name"
                                                    name="audioFile"
                                                    control={control}
                                                    rules={{
                                                        pattern: {
                                                            value: /^.*\.(mp3|wav|mov|flac)$/,
                                                            message: 'Not valid audio file format (mp3, wav, mov, flac only)',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Column>
                                                <Textarea
                                                    control={control}
                                                    name="description"
                                                    title="Description"
                                                    rules={{
                                                        validate: async value => {
                                                            const byteSize = new Blob([value]).size;

                                                            if (byteSize > 5000) {
                                                                return 'Description is too long';
                                                            }

                                                            return true;
                                                        },
                                                        pattern: {
                                                            value: /^[^<>]*$/,
                                                            message: 'You can\'t use characters "<" or ">" in a video description',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                        {isVO && (
                                            <>
                                                <Form.Row>
                                                    <Form.Column>
                                                        <SelectBox
                                                            title="Upload version"
                                                            placeholder="Choose the upload version"
                                                            control={control}
                                                            controllerName="upload.uploadVersion"
                                                            optionsValues={
                                                                (Object.values(VideoUploadVersion) as Array<VideoUploadVersion>)
                                                                    .map(uploadVersionValue => ({
                                                                        id: uploadVersionValue,
                                                                        value: uploadVersionValue,
                                                                        label: uploadVersionValue,
                                                                    }))
                                                            }
                                                        />
                                                    </Form.Column>
                                                    <Form.Column>
                                                        <InputPrefix
                                                            prefix={videoURLBase}
                                                            title="Video ID"
                                                            icon={(
                                                                <CopyTextIcon
                                                                    textToCopy={videoURLBase + videoIdToCopy}
                                                                    tooltip="Copy full video URL"
                                                                    isInputPrefix={true}
                                                                />
                                                            )}
                                                        >
                                                            <Input
                                                                name="upload.videoId"
                                                                control={control}
                                                                rules={{
                                                                    validate: async value => {
                                                                        if (value === '' || activeUploadId === value) {
                                                                            return true;
                                                                        }

                                                                        const patternValidation = validateYoutubeVideoId(value);

                                                                        if (typeof patternValidation === 'string') {
                                                                            return patternValidation;
                                                                        }

                                                                        const {data: {uploadVideoIdExists}} = await apolloClient.query({
                                                                            query: CheckYoutubeVideoIdExistsDocument,
                                                                            variables: {videoId: value},
                                                                        });

                                                                        if (uploadVideoIdExists === true) {
                                                                            return 'This Youtube ID is already in use';
                                                                        }

                                                                        return true;
                                                                    },
                                                                }}
                                                            />
                                                        </InputPrefix>
                                                    </Form.Column>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Column>
                                                        <SelectBox
                                                            title="Upload policy"
                                                            placeholder="Choose the upload policy"
                                                            control={control}
                                                            controllerName="upload.uploadPolicyId"
                                                            optionsValues={
                                                                videoMutations?.uploadPolicyAttributes.map(uploadPolicy => ({
                                                                    id: uploadPolicy?.id,
                                                                    value: uploadPolicy?.id,
                                                                    label: uploadPolicy?.name,
                                                                }))
                                                            }
                                                            canType={true}
                                                        />
                                                    </Form.Column>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Column>
                                                        <Input
                                                            title="Filename"
                                                            name="upload.filename"
                                                            control={control}
                                                            rules={{
                                                                pattern: {
                                                                    value: /^.*\.(mp4|mov|avi)$/,
                                                                    message: 'Not valid video file format (.mp4, .mov or .avi only)',
                                                                },
                                                                maxLength: {
                                                                    value: 255,
                                                                    message: 'It can\'t be longer then 255 characters',
                                                                },
                                                            }}
                                                        />
                                                    </Form.Column>
                                                    <Form.Column>
                                                        <Input
                                                            title="Custom ID"
                                                            name="upload.customId"
                                                            control={control}
                                                            rules={{
                                                                maxLength: {
                                                                    value: 255,
                                                                    message: 'It can\'t be longer then 255 characters',
                                                                },
                                                            }}
                                                        />
                                                    </Form.Column>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Column>
                                                        <Input
                                                            title="Custom thumbnail"
                                                            name="upload.customThumbnail"
                                                            control={control}
                                                            rules={{
                                                                pattern: {
                                                                    value: /^.*\.(jpg|png|jpeg|gif)$/,
                                                                    message: 'Not valid image file format (.jpg, .jpeg, .png or .gif)',
                                                                },
                                                                maxLength: {
                                                                    value: 255,
                                                                    message: 'It can\'t be longer then 255 characters',
                                                                },
                                                            }}
                                                        />
                                                    </Form.Column>
                                                    <Form.Column>
                                                        {videoIdToCopy && videoIdToCopy !== '' && (
                                                            <div className={videoStyles.thumbnailPreview}>
                                                                <img
                                                                    src={`https://i.ytimg.com/vi/${videoIdToCopy}/0.jpg`}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        )}
                                                    </Form.Column>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Column>
                                                        <SelectBox
                                                            title="Category"
                                                            placeholder="Choose the category"
                                                            control={control}
                                                            controllerName="upload.categoryId"
                                                            optionsValues={
                                                                videoMutations?.categoryAttributes.map(category => ({
                                                                    id: category?.id,
                                                                    value: category?.id,
                                                                    label: category?.name,
                                                                }))
                                                            }
                                                        />
                                                    </Form.Column>
                                                    <Form.Column>
                                                        <SelectBox
                                                            title="Privacy"
                                                            placeholder="Choose the privacy"
                                                            control={control}
                                                            controllerName="upload.privacy"
                                                            optionsValues={
                                                                videoMutations?.privacyAttributes.map(privacy => ({
                                                                    id: privacy?.id,
                                                                    value: privacy?.name,
                                                                    label: `${privacy?.name.charAt(0).toUpperCase()}${privacy?.name.slice(1)}`,
                                                                }))
                                                            }
                                                        />
                                                    </Form.Column>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Column hasHalfWidth={true}>
                                                        <TagsInput
                                                            title="KWs (tags)"
                                                            name="upload.keywords"
                                                            control={control}
                                                            placeholder={'Type tag and press "Enter" or "comma"'}
                                                            rules={{
                                                                validate: value => validateYoutubeVideoTags(value),
                                                            }}
                                                        />
                                                    </Form.Column>
                                                    <Form.Column alignVertical="center">
                                                        <>
                                                            {isVO && (
                                                                <Controller
                                                                    control={control}
                                                                    name="upload.notifySubscribers"
                                                                    render={({
                                                                        field: {
                                                                            onChange,
                                                                            value,
                                                                        },
                                                                    }) => (
                                                                        <Checkbox
                                                                            name="upload.notifySubscribers"
                                                                            label="Notify subscribers"
                                                                            checked={value}
                                                                            handleChange={() => {
                                                                                onChange(value !== true);
                                                                            }}
                                                                            disabled={true}
                                                                        />
                                                                    )}
                                                                />
                                                            )}
                                                        </>
                                                    </Form.Column>
                                                </Form.Row>
                                                <Spacer height="2rem"/>
                                            </>
                                        )}
                                        <Form.Row>
                                            <Form.Column>
                                                Included in playlists
                                                <div className={styles.playlists}>
                                                    {playlists.length > 0 && playlists.map(playlist => (
                                                        <Link key={playlist.id} to={`/playlists/${params.channel}/${playlist.id}`}>
                                                            {playlist.name}
                                                        </Link>
                                                    ))}
                                                    {playlists.length === 0 && 'none'}
                                                </div>
                                            </Form.Column>
                                        </Form.Row>
                                        <Spacer height="2rem"/>
                                        <Form.Row>
                                            <Form.Column>
                                                <Textarea
                                                    control={control}
                                                    name="note"
                                                    title="Notes"
                                                    rules={{
                                                        maxLength: {
                                                            value: 255,
                                                            message: 'It can\'t be longer then 255 characters',
                                                        },
                                                    }}
                                                />
                                            </Form.Column>
                                        </Form.Row>
                                    </Form>
                                </div>
                                <div
                                    className={clsx(
                                        layoutStyles.pageSection,
                                        layoutStyles.bottomButtons,
                                    )}
                                >
                                    <h3>Danger zone</h3>
                                    <div>
                                        <Button
                                            text={`Delete whole ${isVO ? 'version' : 'translation'}`}
                                            color="error"
                                            onClick={handleDeleteMutation}
                                            disabled={isSendingEdit}
                                            icon={DeleteIcon}
                                        />

                                        <Button
                                            text="Changes history"
                                            color="white"
                                            onClick={() => {setOpenedModal('history');}}
                                            size="medium"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Layout>
            {openedModal && openedModal === VideoModalVariants.history && (
                <HistoryModal setModal={setOpenedModal} mutationId={(mutationData && mutationData.mutation) ? mutationData.mutation.id : ''} />
            )}
            {discardWarningDisplayed && (
                <WarningPopup
                    topText={[
                        'Whoa, wait!',
                        <br key="linebreak" />,
                        'You are about to discard all the unsaved changes. Do you really want to do that?',
                    ]}
                    buttons={(
                        <>
                            <Button
                                text="Yes, discard all changes"
                                onClick={onDiscardWarningConfirm}
                                color={'error'}
                            />
                            <Button
                                text="No, take me back to editing"
                                onClick={() => {
                                    setDiscardWarningDisplayed(false);
                                }}
                            />
                        </>
                    )}
                />
            )}
            {deleteWarningDisplayed && (
                <WarningPopup
                    topText={[<div key="warning-topText">Do you really wanna delete ${isVO ? 'version' : 'translation'} &quot;{activeMutationName}&quot;?</div>, playlists.length > 0 ? <div key="warning-topText-2">Keep in mind it is included in {playlists.length} playlist(s)</div> : null]}
                    buttons={(
                        <>
                            <Button
                                text="Yes, delete it"
                                onClick={onDeleteWarningConfirm}
                                color={'error'}
                                disabled={isSendingDelete}
                                loading={isSendingDelete}
                            />
                            <Button
                                text="No, keep it"
                                onClick={() => {
                                    setDeleteWarningDisplayed(false);
                                }}
                            />
                        </>
                    )}
                />
            )}
        </>
    );
};

export default VideoMutationsEditPage;
